document.addEventListener('DOMContentLoaded', function() {
  let hasScrolled = false;

  window.addEventListener('scroll', function() {
      if (!hasScrolled) {
          hasScrolled = true;
          document.querySelector('.menu-fixed-btn-wrapper').classList.remove('visually-hidden');
      }
  });
});
