import Modal from 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie';

// popup variables
const options = {backdrop: 'static', keyboard: false};
const welcomePopup = new Modal($('#welcome-popup'), options);

// legal age popup functions
$(document).on('click', '#welcome-popup-confirm', () => {
  const welcomePopupRead = $('#welcome-popup-read').is(':checked');
  if (welcomePopupRead) {
    welcomePopup.hide();
    Cookies.set('welcomePopupRead', welcomePopupRead);
  }
});

$(document).on('click', '#welcome-popup-input', () => {
  const welcomePopupRead = $('#welcome-popup-read').is(':checked');
  $('#welcome-popup-confirm').prop('disabled', !welcomePopupRead);
});

if (Cookies.get('welcomePopupRead') != "true") {
    welcomePopup.show();
}
