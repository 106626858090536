// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'js-cookie';

import './modules/hamburger';
import './modules/mapbox';
import './modules/sticky-header';
import './modules/sliders';
import './modules/aos';
import './video';
import './floating_buttons';
import './modules/popups';
