const $window = $(window);
const body = $('body');
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
const scrolledTop = "scrolled-top";
let lastScroll = 0;

let stickyHeader = () => {
	const currentScroll = window.scrollY;
	if (currentScroll <= 50) {
		body.removeClass(scrollUp);
		body.addClass(scrolledTop);
	} else {
		body.removeClass(scrolledTop);
	}

	if (currentScroll > lastScroll && !body.hasClass(scrollDown)) {
		// down
		body.removeClass(scrollUp);
		body.addClass(scrollDown);
	} else if (currentScroll < lastScroll && body.hasClass(scrollDown)) {
		// up
		body.removeClass(scrollDown);
		body.addClass(scrollUp);
	}
	lastScroll = currentScroll;
};

$window.on('load scroll', () => {
	stickyHeader();
});