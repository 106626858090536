/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Slider rooms */
var rooms_swiper = new Swiper(".rooms-swiper", {
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,

  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

/* Slider */
var carousel_swiper = new Swiper(".carousel-swiper", {
  slidesPerView: 'auto',
  spaceBetween: 0,

  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  on: {
    init: function() {
      // Récupère la slide active
      let activeSlideIndex = this.activeIndex;

      // Crée un tableau de titres
      let titles = $(".room-name");
      let titlesArr= Array.from(titles);

      // // Récupère l'image correspondante à la slide active
      let activeTitle = titlesArr.filter(title => title.getAttribute("data-id") == activeSlideIndex );

      // Ajoute le "fade-in effect" au titre de slide associé
      if(activeTitle[0].getAttribute("data-id") == activeSlideIndex) {
        activeTitle[0].classList.add("fade-in-title");
      }
    },

    slideChangeTransitionStart: function() {
      let activeSlideIndex = this.activeIndex;
      let titles = $(".room-name");
      let titlesArr= Array.from(titles);
      let activeTitle = titlesArr.filter(title => title.getAttribute("data-id") == activeSlideIndex );

      if(activeTitle[0].getAttribute("data-id") == activeSlideIndex) {
        $(".fade-in-title")[0].classList.remove("fade-in-title");
        activeTitle[0].classList.add("fade-in-title");
      }
    },
  },
});
