var hamburger = $('.hamburger');
var hamburger_box = $('.hamburger-box');
var hamburger_logo = $('.svg-burger-dims');
var offcanvas = $('#offcanvas-menu');
var header = $('.header');
var body = $('body');
var $fixed_buttons = $('.menu-fixed-btn-wrapper');

hamburger.on('click', () => {
  hamburger.toggleClass('is-active');
  hamburger_box.toggleClass('is-visible');
  hamburger_logo.toggleClass('is-hidden');
  header.toggleClass('mobile-nav-open');
  offcanvas.toggleClass('show');
  body.toggleClass('menu-open');
  $fixed_buttons.toggleClass('d-none');
});

offcanvas.on('hidden.bs.offcanvas', () => {
  hamburger.removeClass('is-active');
  header.removeClass('mobile-nav-open');
  body.removeClass('menu-open');
});
